<template>
  <div>

    <el-dialog title="填写发票信息" class="qrCodeDialog" append-to-body :visible.sync="qrCodeDialogShow" width="578px" :before-close="resetDatas" v-loading.fullscreen.lock="applyLoad"
      element-loading-background="rgba(255, 255, 255, 0.1)">
      <div class="invoiceType">
        <span>发票类型</span>
        <el-button round size="small" plain :class="{'active':infoIsShow}" @click="switchType(true)" v-if="eInvoice">普通发票（数电票）</el-button>
        <el-button round size="small" plain :class="{'active':!infoIsShow}" @click="switchType(false)" v-if="showVat">增值税专用发票（数电票）</el-button>
      </div>
      <div class="QrCodeBox" v-if="infoIsShow">
        <el-form :model="information" :rules="rules" ref="ruleForm" label-width="108px">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="企业" name="first">
              <div v-if="activeName =='first'">
                <el-form-item label="发票抬头" prop="invoiceTitle" size="medium">
                  <el-input v-model="information.invoiceTitle" placeholder="请输入发票抬头" maxlength="60"></el-input>
                </el-form-item>
                <el-form-item label="公司税号" prop="taxNo" size="medium">
                  <el-input v-model="information.taxNo" placeholder="请输入公司税号" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phoneNumber" size="medium">
                  <el-input v-model="information.phoneNumber" placeholder="请输入接收发票的手机号" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email" size="medium">
                  <el-input v-model="information.email" placeholder="请输入接收发票邮箱" maxlength="30"></el-input>
                </el-form-item>
                <el-button type="primary" size="medium" class="applyInvoice" @click="applyInvoice">申请开票</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="个人/其他" name="second">
              <div v-if="activeName =='second'">
                <el-form-item label="发票抬头" prop="invoiceTitle" size="medium">
                  <el-input v-model="information.invoiceTitle" placeholder="个人/非企业性质单位" maxlength="60"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="" size="medium">
                  <el-input v-model="information.taxNo" placeholder="机关事业单位可不填写" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phoneNumber" size="medium">
                  <el-input v-model="information.phoneNumber" placeholder="请输入接收发票的手机号" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email" size="medium">
                  <el-input v-model="information.email" placeholder="请输入接收发票邮箱" maxlength="30"></el-input>
                </el-form-item>
                <el-button type="primary" size="medium" class="applyInvoice" @click="applyInvoice">申请开票</el-button>
              </div>

            </el-tab-pane>
          </el-tabs>
        </el-form>
      </div>

      <div class="invoiceInfo" v-else>
        <h5>注意：仅支持对公账户付款的用户，专票开具成功后发送您的手机号或邮箱</h5>
        <el-form :model="information" :rules="rules" ref="ruleForm" label-width="108px">
          <el-form-item label="付款账号" prop="remitBankNo" size="medium">
            <el-input v-model="information.remitBankNo" placeholder="请输入付款银行账号" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="户名" prop="remitName" size="medium">
            <el-input v-model="information.remitName" placeholder="请输入户名" maxlength="60"></el-input>
          </el-form-item>
          <el-form-item label="付款凭证" prop="paymentVoucher" size="medium">
            <el-upload :action="uploadVoucher" :headers="myHeader" :on-success="handleSuccess" :before-upload="beforeUpload" :show-file-list="false" accept="image/png,image/jpg,image/jpeg"
              v-if="information.paymentVoucher===''">
              <el-button type="primary" size="medium">上传</el-button>
            </el-upload>
            <p v-else><span @click="viewFile">{{fileName}}</span>
              <el-button type="text" @click="deleteUpload">删除</el-button>
            </p>
            <h4 v-if="errorMsg">{{errorMsg}}</h4>
          </el-form-item>
          <el-form-item label="发票抬头" prop="header" size="medium">
            <el-input v-model="information.header" placeholder="请输入发票抬头" maxlength="60"></el-input>
          </el-form-item>
          <el-form-item label="公司税号" prop="taxNo" size="medium">
            <el-input v-model="information.taxNo" placeholder="请输入公司税号" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="对公账号" prop="acctNo" size="medium">
            <el-input v-model="information.acctNo" placeholder="请输入对公账号" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="开户行" prop="bankBranch" size="medium">
            <el-input v-model="information.bankBranch" placeholder="请输入开户行" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="companyAddress" size="medium">
            <el-input v-model="information.companyAddress" placeholder="请输入公司地址" maxlength="60"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone" size="medium">
            <el-input v-model="information.phone" placeholder="请输入电话" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="telephone" size="medium">
            <el-input v-model="information.telephone" placeholder="请输入接收发票的手机号" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email" size="medium">
            <el-input v-model="information.email" placeholder="请输入接收发票的邮箱" maxlength="30"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" size="medium" class="applyInvoice" @click="applyInvoice(2)">申请开票</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看付款凭证" :visible.sync="voucherShow" width="950px" center>
      <img class="paymentVoucher" :src="information.paymentVoucher">
    </el-dialog>
  </div>
</template>
<script>
import citysData from '@/utils/citys'
export default {
  data() {
    return {
      applyLoad: false,
      qrCodeDialogShow: false,
      qrLoading: false,
      showVat: false,
      eInvoice: false,
      InvoiceQrCode: '',
      infoIsShow: true,
      provinceAndCity: '',
      citysData: [],
      fileName: '',
      activeName: 'first',
      information: {
        remitBankNo: '',
        remitName: '',
        paymentVoucher: '',
        header: '',
        acctNo: '',
        bankBranch: '',
        companyAddress: '',
        telephone: '',
        phone: '',
        taxNo: '',
        phoneNumber: '',
        invoiceTitle: '',
        email: '',
        invoiceCategory: ''
      },
      rules: {
        phoneNumber: [
          {
            required: true,
            message: '请输入接收发票的手机号',
            trigger: 'change'
          },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: '请输入正确的接收发票的手机号',
            trigger: 'blur'
          }
        ],
        invoiceTitle: [
          { required: true, message: '请输入发票抬头', trigger: 'change' }
        ],
        taxNo: [
          { required: true, message: '请输入公司税号', trigger: 'change' },
          {
            message: '请输入正确的公司税号',
            trigger: 'blur',
            pattern: /^[A-Z0-9]+$/
          }
        ],
        email: [
          { required: true, message: '请输入接收发票邮箱', trigger: 'blur' },
          {
            message: '请输入正确的电子邮箱',
            trigger: 'blur',
            pattern: /^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+[a-z]{2,}$/
          }
        ],
        remitBankNo: [
          { required: true, message: '请输入付款银行账号', trigger: 'change' }
        ],
        remitName: [
          { required: true, message: '请输入户名', trigger: 'change' }
        ],
        header: [
          { required: true, message: '请输入发票抬头', trigger: 'change' }
        ],
        telephone: [
          { required: true, message: '请输入收件人手机号', trigger: 'change' },
          {
            pattern: /^1[3|5|7|8]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
        stateOrProvince: [
          { required: true, message: '请选择收获地址', trigger: 'change' }
        ],
        paymentVoucher: [
          { required: true, message: '请上传付款凭证', trigger: 'change' }
        ],
        acctNo: [
          { required: true, message: '请输入对公账号', trigger: 'change' }
        ],
        bankBranch: [
          { required: true, message: '请输入开户行', trigger: 'change' }
        ],
        companyAddress: [
          { required: true, message: '请输入公司地址', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'change' },
          {
            pattern: /^(0\d{2,3}-)?([2-9]\d{6,7})+(-\d{1,6})?$|^((\+86|\+86-)|(86|86-)|(0086|0086-))?1(3|4|5|6|7|8|9)\d{9}$/,
            message: '请输入正确的联系电话',
            trigger: 'blur'
          }
        ]
      },
      uploadVoucher: '',
      myHeader: {
        Authorization: sessionStorage.getItem('token')
      },
      voucherShow: false,
      errorMsg: false,
      transId: ''
    }
  },
  created() {
    this.clearCitysData()
  },
  mounted() {
    this.uploadVoucher = this.CONFIG.baseURL + '/portal/file/upload-voucher'
  },
  methods: {
    handleClick() {
      this.information.taxNo = ''
      this.$refs['ruleForm'].resetFields()
    },
    switchType(val) {
      if (val !== this.infoIsShow) {
        this.infoIsShow = val
        this.information.paymentVoucher = ''
        this.provinceAndCity = ''
      }
      if (this.$refs['ruleForm'] !== undefined) {
        this.information.taxNo = ''
        this.$refs['ruleForm'].resetFields()
      }
    },
    clearCitysData() {
      for (let province of citysData) {
        for (let city of province.children) {
          delete city.children
        }
      }
      this.citysData = citysData
    },
    handleSuccess(res) {
      if (res.code === '0') {
        this.information.paymentVoucher = res.data.url
        this.$refs.ruleForm.validateField('paymentVoucher')
      } else {
        this.$message.error(res.msg)
      }
    },
    beforeUpload(file) {
      const isIMG =
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isIMG) {
        this.$message.error('上传头像图片只能是 png/jpg/jpeg 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
        return false
      }
      this.fileName = file.name
    },
    viewFile() {
      this.voucherShow = true
    },
    deleteUpload() {
      this.fileName = ''
      this.information.paymentVoucher = ''
    },
    getShow(transId, order) {
      this.transId = transId
      this.qrCodeDialogShow = true
      console.log(order)
      if (order === 'order') {
        this.getInvoiceQrCode(transId, order)
      }
      // if (order !== undefined) {
      //   this.getInvoiceQrCode(transId, order)
      // } else if (this.eInvoice) {
      //   this.getInvoiceQrCode(transId)
      // }
    },
    getInvoiceQrCode(transId, order) {
      let fn, data
      if (order === 'order') {
        fn = this.$api.getOrderInvoiceGetCode
        data = { orderNo: transId || '' }
      } else if (order === 'report') {
        fn = this.$api.getReportInvoicQrcode
        data = { transactionId: transId || '' }
      } else {
        fn = this.$api.getInvoiceGetCode
        data = { transId: transId || '' }
      }
      this.qrLoading = true
      this.qrCodeDialogShow = true
      fn(data)
        .then(data => {
          this.qrLoading = false
          if (data && data.code === '0') {
            this.InvoiceQrCode = 'data:image/jpeg;base64,' + data.data
          } else if (data.code === '500') {
            this.isShow = true
            this.title = '温馨提示'
            this.message = '系统繁忙，请稍候再试'
          } else {
            this.errorcode = data.msg
          }
        })
        .catch(() => {
          this.qrLoading = false
        })
    },
    downloadFile(url, name, type = 'file') {
      if (type === 'file') {
        let xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = function() {
          if (xhr.status === 200) {
            saveAs(xhr.response, name)
          }
        }
        xhr.send()
      } else if (type === 'base64') {
        let blob = base64ToBlob(url)
        saveAs(blob, name)
      }

      function base64ToBlob(code) {
        let parts = code.split(';base64,')
        let contentType = parts[0].split(':')[1]
        let raw = window.atob(parts[1])
        let rawLength = raw.length

        let uInt8Array = new Uint8Array(rawLength)

        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i)
        }
        return new Blob([uInt8Array], {
          type: contentType
        })
      }

      function saveAs(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename)
        } else {
          let link = document.createElement('a')
          let body = document.querySelector('body')

          link.href = window.URL.createObjectURL(blob)
          link.download = filename

          // fix Firefox
          link.style.display = 'none'
          body.appendChild(link)

          link.click()
          body.removeChild(link)

          window.URL.revokeObjectURL(link.href)
        }
      }
    },

    applyInvoice() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.applyVatInvoice()
        } else {
          return false
        }
      })
    },
    applyVatInvoice() {
      let url = 'applyInvoice'
      this.applyLoad = true
      let data = {
        taxNo: this.information.taxNo,
        phoneNumber: this.information.phoneNumber,
        invoiceTitle: this.information.invoiceTitle,
        email: this.information.email,
        transId: this.transId,
        invoiceCategory: 'p'
      }
      if (this.information.header) {
        url = 'applyVatInvoice'
        delete data.phoneNumber
        delete data.invoiceCategory
        data = {
          ...data,
          remitBankNo: this.information.remitBankNo,
          remitName: this.information.remitName,
          voucherUrl: this.information.paymentVoucher,
          header: this.information.header,
          acctNo: this.information.acctNo,
          bankBranch: this.information.bankBranch,
          companyAddress: this.information.companyAddress,
          telephone: this.information.telephone,
          phone: this.information.phone
        }
      }
      this.$api[url](data)
        .then(res => {
          this.applyLoad = false
          if (res.code === '0') {
            this.resetDatas()
            this.$message({
              message: '申请开票成功！',
              type: 'success'
            })
            this.$parent.getTransInvoiceList()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {
          this.applyLoad = false
          // this.$message.error(err.msg)
        })
    },
    resetDatas() {
      this.qrCodeDialogShow = false
      this.switchType(true)
    }
  }
}
</script>
<style lang="scss" scoped>
.qrCodeDialog {
  ::v-deep .el-dialog {
    // padding: 0 24px;
    border-radius: 4px;
    .el-dialog__header {
      padding: 0;
      border-bottom: 1px #dee2e8 solid;
      line-height: 44px;
      color: #2a2b2c;
      text-align: center;
      font-weight: 700;
      .el-dialog__title {
        font-size: 14px;
      }
      .el-dialog__headerbtn {
        top: 12px;
      }
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
.QrCodeBox {
  text-align: center;
  padding-bottom: 32px;
  ::v-deep.el-tabs {
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
      width: 100%;
      .el-tabs__item {
        // width: 180px;
        &:nth-child(2) {
          padding-right: 80px;
        }
        &:last-child {
          padding-left: 80px;
        }
      }
    }
    .el-tabs__header {
      padding-bottom: 10px;
    }
    .el-tabs__content {
      padding: 0 24px;
    }
  }
  .image {
    width: 134px;
    height: 134px;
    border-radius: 2px 2px 0px 0px;
    border: 1px solid #d5d9eb;
    padding: 8px;
    margin: 24px auto 12px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  span {
    display: block;
    font-size: 12px;
    color: #595959;
    line-height: 16px;
  }
  .el-button--text {
    color: #0084ff;
    letter-spacing: 1px;
  }
  .el-button--text:focus,
  .el-button--text:hover {
    color: #409eff;
  }
}
.invoiceType {
  padding: 24px 48px;
  span {
    display: inline-block;
    width: 80px;
  }
  .el-button + .el-button {
    margin-left: 16px;
  }
  ::v-deep .el-button {
    padding: 9px 14px;
    color: #333333;
    background: #d8d8d8;
    letter-spacing: 1px;
    &:hover {
      color: #1c67f3;
      background: #d9e7ff;
      border: 1px solid #1c67f3;
    }
    span {
      color: unset;
    }
  }
  .active {
    color: #1c67f3;
    background: #d9e7ff;
    border: 1px solid #1c67f3;
  }
}
.invoiceInfo {
  padding: 0 24px 24px;
  h5 {
    font-size: 14px;
    color: #e02020;
    line-height: 19px;
    letter-spacing: 1px;
    margin-bottom: 24px;
    padding-left: 24px;
    font-weight: normal;
  }
  .el-form-item {
    margin-bottom: 24px;
  }
  ::v-deep .el-form-item__label {
    padding-right: 24px;
  }
  ::v-deep .el-input {
    width: 408px;
  }
  ::v-deep .el-button {
    width: 78px;
    line-height: 14px;
  }
  .el-button.el-button--text {
    width: auto;
    padding: 10px;
  }
  h4 {
    font-size: 12px;
    color: #e02020;
    line-height: 12px;
    letter-spacing: 1px;
    font-weight: normal;
    position: absolute;
    bottom: -18px;
  }
  .applyInvoice {
    display: block;
    width: 130px;
    margin: 8px auto 0;
  }
  p > span {
    cursor: pointer;
    &:hover {
      color: rgba($primaryColor, 0.8);
    }
  }
}
.paymentVoucher {
  width: 100%;
}
</style>